.user-dashboard html body .MuiPickersCalendarHeader-dayLabel{
    font-size: 0.625rem;
    text-transform: uppercase;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    font-size: 16px;
}

/* .MuiDataGrid-root .MuiDataGrid-sortIcon {
    
    visibility: hidden !important;  
} */

.google_button{
    border-radius: 4px !important;
    font-size: 14px !important;
    line-height: 40px !important;
    padding:  0 2px !important;
}

.innerTextGoogleBtn{
    width: 100%;
    padding: 2px !important;
}

@media (min-width: 320px) {
.MuiDataGrid-root .MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount {
    width: auto;
    height: auto;
    visibility: hidden !important;
}
.pl-8 {
    padding-left: 0.5rem;
}
.resp-top{
    padding-top: 0rem;
}
}