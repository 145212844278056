.top-bar-menu{
    justify-content: flex-start;
    align-content: center;
    justify-items: center;
    align-items: center;
    height: 50px;
}

.mobile-menu-bg{
    background-color: #333;
}
.letter-space-14{
    letter-spacing: 1.4px;
}
.nav-sep{
    background-color: var(--gold-yellow);
    width: 2px;
    height: 7px;
  }

.top-bar-menu a:hover{
    color: #fc8f00;
}

.error-alert{
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    border-radius: 4px;
    background-color: rgb(253, 237, 237);
    display: flex;
    color: rgb(95, 33, 32);
}
.error-alert svg{color: rgb(95, 33, 32);}

.warning-alert{
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    border-radius: 4px;
    background-color: rgb(255, 244, 229);
    display: flex;
    padding: 6px 16px;
    color: rgb(102, 60, 0);
}

.warning-alert svg {color: rgb(102, 60, 0);}

.info-alert{
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    border-radius: 4px;
    background-color: rgb(229, 246, 253);
    display: flex;
    color: rgb(1, 67, 97);
}
.info-alert svg{color: rgb(1, 67, 97);}

.success-alert{
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: none;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    border-radius: 4px;
    background-color: rgb(237, 247, 237);
    display: flex;
    color: rgb(30, 70, 32);
}
.success-alert svg{color: rgb(30, 70, 32);}

@media screen and (max-width: 479px){

.menu a {
    color: #FFFFFF
}
}