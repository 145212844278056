/* .MuiStepIcon-root.MuiStepIcon-active  {
    color: #49b53f !important;
} */

.MuiStepIcon-root.MuiStepIcon-completed {
    color: #69b53f !important;

}



.MuiStepIcon-root.MuiStepIcon-active {
    color: #4285f4 !important;
    font-size: 35px;
}


.MuiStepLabel-labelContainer-active  span {
    font-size: xx-large;
}
/* .MuiStepLabel-labelContainer span {
    font-size: xx-large;
} */



.MuiStepLabel-label.MuiStepLabel-active {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    margin-top: 5px !important;
    
}