.user-dashboard html body .MuiPickersCalendarHeader-dayLabel{
    font-size: 0.625rem;
    text-transform: uppercase;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    font-size: 16px;
}

/* .MuiDataGrid-root .MuiDataGrid-sortIcon {
    
    visibility: hidden !important;  
} */

@media (min-width: 600px) {
.MuiDataGrid-root .MuiDataGrid-footerContainer .MuiDataGrid-selectedRowCount {
    width: auto;
    height: auto;
    visibility: hidden !important;
}
}