.user-dashboard html body .MuiPickersCalendarHeader-dayLabel{
    font-size: 0.625rem;
    text-transform: uppercase;
}


.MuiDataGrid-columnHeaderTitle {
    font-weight: 600 !important;
}

.flip-image {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}