.user-dashboard html body .MuiPickersCalendarHeader-dayLabel{
    font-size: 0.625rem;
    text-transform: uppercase;
}
.MuiInputBase-input{
    margin-bottom: 12px!important;
}

.lastcolumnSeparator  {
    visibility: hidden;
}
